import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector((state) => state.auth.token);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && props.match.path === "/" && props.match.isExact ? (
          <>
            <Redirect to={"/dashboard"} /> <Component {...props} />
          </>
        ) : isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
