import useNavigation from "@hooks/useNavigation";
import useSidebar from "@hooks/useSidebar";
import { AiOutlineUser } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Nav } from "reactstrap";
import useTenant from "../../hooks/useTenant";

const Sidebar = (props) => {
  const { logo } = useTenant();
  const { scrollTop } = useSidebar(props);
  const { logout } = useNavigation(props);
  const { applicationRoutes } = props;
  const mapLinks = applicationRoutes.dashboard
    .filter((route) => route.visibleOnSidebar)
    .map((prop) => {
      return (
        <li onClick={scrollTop} key={prop.path} className="first-level">
          <NavLink
            to={{
              pathname: prop.path,
              state: { breadCrumbs: prop.path },
            }}
            exact
            className="sidebar-link"
            activeClassName="active"
          >
            {prop.sidebarIcon}
            <span className="hide-menu">
              <FormattedMessage id={prop.sidebarName} />
            </span>
          </NavLink>
        </li>
      );
    });

  return (
    <aside
      className={
        props.status && matchMedia("only screen and (max-width: 764px)").matches
          ? "left-sidebar hidden"
          : "left-sidebar"
      }
      id="sidebarbg"
      data-sidebarbg="skin6"
    >
      <div className="left-sidebar-inner">
        <MediaQuery query="(max-device-width: 764px)">
          <i onClick={props.toggleSidebar} className="ti-close ti-close" />
        </MediaQuery>

        <Link to="/dashboard" className="logo">
          <img src={logo} alt="logo" />
        </Link>

        <div className="scroll-sidebar">
          <div className="scrollbar-container sidebar-nav ps--active-y">
            <Nav id="sidebarnav" className="selected sidebar-item">
              {mapLinks}
            </Nav>
            <div onClick={scrollTop} className="nav-additional">
              <AiOutlineUser />
              <NavLink
                to={{
                  pathname: "/my-details",
                }}
              >
                <FormattedMessage id="main.sidebar.myDetails" />
              </NavLink>
            </div>
            <MediaQuery query="(max-device-width: 764px)">
              <div onClick={scrollTop} className="nav-additional">
                <span onClick={logout}>
                  <i className="mdi mdi-logout" />
                  <FormattedMessage id="main.navigation.logOut" />
                </span>
              </div>
            </MediaQuery>
          </div>
        </div>
      </div>
    </aside>
  );
};
export default withRouter(Sidebar);
