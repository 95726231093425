import "@assets/scss/style.scss";
import { Toastr } from "@components/Toastr";
import store from "@store";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const bootApp = async () => {
  // Load the translations based on the browser settings
  // Helper -> Validate if language is exists
  let locale = process.env.REACT_APP_DEFAULT_LOCALE;

  const defaultMessages = (await import(`./assets/i18n/${locale}.json`))
    .default;
  const overrideMessages = (
    await import(`./assets/i18n/override/${locale}.json`)
  ).default;

  const messages = {
    ...defaultMessages,
    ...overrideMessages,
  };

  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <IntlProvider
          locale={locale}
          messages={messages}
          defaultLocale={process.env.REACT_APP_DEFAULT_LOCALE}
          defaultFormats={{
            number: {
              style: "currency",
              currency: "EUR",
              currencyDisplay: "symbol",
              maximumSignificantDigits: 2,
            },
          }}
          formats={{
            number: {
              style: "currency",
              currency: "EUR",
              currencyDisplay: "symbol",
              maximumSignificantDigits: 2,
            },
          }}
        >
          <BrowserRouter>
            <HelmetProvider>
              <App />
              <Toastr />
            </HelmetProvider>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    </StrictMode>,
    document.getElementById("root")
  );
};

bootApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
