import CompleteAccountRoutes from "@components/Router/CompleteAccountRoutes";
import { Spinner } from "@components/Spinner";
import useApplicationRoutes from "@hooks/useApplicationRoutes";
import useRenderDashboard from "@hooks/useRenderDashboard";

const CompleteAccountLayout = () => {
  const { render } = useRenderDashboard();
  const { applicationRoutes, sidebarRender } = useApplicationRoutes();

  if (!render || !sidebarRender) return <Spinner />;

  return (
    <div className="Page">
      <CompleteAccountRoutes applicationRoutes={applicationRoutes} />
    </div>
  );
};

export default CompleteAccountLayout;
