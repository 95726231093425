import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import useTenant from "../../hooks/useTenant";
import useConfirmEmailChange from "../../hooks/useConfirmEmailChange";

const ConfirmEmailChange = () => {
  const { logo, companyName, logoLoading } = useTenant();
  const { isLoading, validToken, activated } = useConfirmEmailChange();
  return (
    <div className={"Activation"}>
      <Row>
        <Col md={12}>
          <div className="img-wrapper">
            {!logoLoading ? (
              <img src={logo} alt="logo" height={"100px"} />
            ) : null}
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col md={12}>
          <Card>
            <CardTitle className="p-1 mb-0">
              <h2 className={"text-secondary"}>
                <FormattedMessage
                  id="auth.changeemailaddress.welcome"
                  values={{ tenantName: companyName }}
                />
              </h2>
            </CardTitle>
            <CardTitle className="p-1 mb-0">
              <FormattedMessage id="auth.changeemailaddress.title" />
            </CardTitle>
            <CardBody>
              <Row>
                <Col md={12} className={"font-12 p-1"}>
                  {!isLoading ? (
                    validToken && activated ? (
                      <FormattedMessage
                        id={"auth.changeemailaddress.introductionline1"}
                      />
                    ) : (
                      <span className={"error"}>
                        <FormattedMessage
                          id={"auth.changeemailaddress.errorline1"}
                        />
                      </span>
                    )
                  ) : null}
                </Col>
              </Row>
              <Row className={"mt-5"}>
                <Col md={12} className={"p-1"}>
                  <Link
                    className={"btn btn-primary"}
                    to={"/auth/login"}
                    style={{ width: "auto" }}
                  >
                    <FormattedMessage id="auth.changeemailaddress.signin" />
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default ConfirmEmailChange;
