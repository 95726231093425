import PreviewInvoice from "@components/Invoice/PreviewInvoice";
import PrivateRoute from "@components/Router/PrivateRoute";
import PreviewPrepaidSaleItem from "@components/Webshop/PreviewPrepaidSaleItem";
import useApp from "@hooks/useApp";
import AuthLayout from "@layouts/Auth";
import CompleteAccount from "@layouts/CompleteAccount";
import DashboardLayout from "@layouts/Dashboard";
import { Helmet } from "react-helmet-async";
import { Route, Switch } from "react-router-dom";
import PreviewDiscountVoucher from "@components/DiscountVoucher/PreviewDiscountVoucher";

const App = () => {
  const { bootApp, projectStyling } = useApp();

  if (!bootApp) return null;

  return (
    <>
      <Helmet>
        <style>{`${projectStyling}`}</style>
      </Helmet>
      <Switch>
        <Route path="/invoice-download/:uuid" component={PreviewInvoice} />
        <Route
          path="/voucher-download/:uuid"
          component={PreviewDiscountVoucher}
        />
        <Route
          path="/webshop-download/:uuid"
          component={PreviewPrepaidSaleItem}
        />
        <Route path="/auth" component={AuthLayout} />
        <Route path="/activate" component={AuthLayout} />
        <Route path="/password" component={AuthLayout} />
        <Route path="/username" component={AuthLayout} />
        <Route
          path={"/discount-vouchers/:discountVoucherUuid/fulfill"}
          component={AuthLayout}
        />
        <PrivateRoute
          path="/complete-personal-details"
          component={CompleteAccount}
        />
        <PrivateRoute path="/" component={DashboardLayout} />
      </Switch>
    </>
  );
};

export default App;
