import {myAccount, myBalance} from "@store/reducers/profile";
import {getPaymentProviderAction} from "@store/reducers/sales";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

const useRenderDashboard = () => {
    const [sidebarHidden, setSidebarVisibility] = useState(true);
    const [render, setRenderDashboard] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const getCustomerData = async () => {
            try {
                await Promise.all([
                    dispatch(myAccount()),
                    dispatch(myBalance()),
                    dispatch(getPaymentProviderAction())])
                setRender(true);
            } catch (e) {
                console.error(e);
            }
        };

        getCustomerData();
    }, []);

    const setRender = (isRender) => setRenderDashboard(isRender);

    const setToggleSidebar = () => setSidebarVisibility(!sidebarHidden);

    return {
        setToggleSidebar,
        sidebarHidden,
        setRender,
        render,
    };
};

export default useRenderDashboard;
