import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiAction } from "@utils/http";
import { apiRoutes } from "@utils";

const reducerName = "auth";

export const handleLogin = createAsyncThunk(
  `${reducerName}/login`,
  async (data = {}, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.authentication.login,
        data,
      });

      return response.headers["x-auth-token"];
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const handleChangePassword = createAsyncThunk(
  `${reducerName}/changepassword`,
  async (data = {}, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.authentication.changePassword,
        urlParams: data.urlParams,
        data: data.data,
      });

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const handleRegister = createAsyncThunk(
  `${reducerName}/register`,
  async (params = {}, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.authentication.register,
        data: params,
      });

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const handleForgotPassword = createAsyncThunk(
  `${reducerName}/forgot-password`,
  async (params = {}, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.authentication.forgotPassword,
        data: params,
      });

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const validateToken = createAsyncThunk(
  `${reducerName}/validatetoken`,
  async (params, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.authentication.validateToken,
        urlParams: params,
      });
      return response.status === 200;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const activateToken = createAsyncThunk(
  `${reducerName}/activatetoken`,
  async (params, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.authentication.activateToken,
        urlParams: params,
      });
      return response.status === 200;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const changeEmailAddressAction = createAsyncThunk(
  `${reducerName}/change-emailaddress`,
  async (params, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.authentication.changeEmailAddress,
        urlParams: params,
      });
      return response.status === 200;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const handleLogout = createAsyncThunk(
  `${reducerName}/logout`,
  async (_, thunkAPI) => {
    try {
      await apiAction({
        ...apiRoutes.authentication.logout,
      });
      return true;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: null,
    validToken: false,
    activated: false,
    isLoading: false,
    error: null,
  },
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers: {
    [handleLogin.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [handleLogin.rejected]: (state, { payload }) => {
      state.token = null;
      state.isLoading = false;
      state.error = payload;
    },
    [handleLogin.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.token = payload;
    },
    [handleRegister.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [handleRegister.rejected]: (state, { payload }) => {
      state.token = null;
      state.isLoading = false;
      state.error = payload;
    },
    [handleRegister.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.token = payload;
    },
    [validateToken.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [validateToken.rejected]: (state, { payload }) => {
      state.validToken = false;
      state.activated = false;
      state.isLoading = false;
      state.error = payload;
    },
    [validateToken.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.validToken = payload;
      state.activated = false;
    },
    [activateToken.pending]: (state) => {
      state.isLoading = true;
      state.validToken = true;
      state.activated = false;
      state.error = null;
    },
    [activateToken.rejected]: (state, { payload }) => {
      state.activated = false;
      state.isLoading = false;
      state.error = payload;
    },
    [activateToken.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.validToken = true;
      state.activated = payload;
    },
    [handleChangePassword.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [handleChangePassword.rejected]: (state, { payload }) => {
      state.token = null;
      state.isLoading = false;
      state.error = payload;
    },
    [handleChangePassword.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.token = payload;
      state.passwordChanged = true;
    },
    [changeEmailAddressAction.pending]: (state) => {
      state.isLoading = true;
      state.validToken = true;
      state.activated = false;
      state.error = null;
    },
    [changeEmailAddressAction.rejected]: (state, { payload }) => {
      state.activated = false;
      state.isLoading = false;
      state.error = payload;
    },
    [changeEmailAddressAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.validToken = true;
      state.activated = payload;
    },
  },
});

export const actions = authSlice.actions;

export default authSlice.reducer;
