import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "subscriptions";

export const getSubscriptionsAction = createAsyncThunk(
  `${reducerName}/get-subscriptions`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.subscriptions,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getSubscriptionAction = createAsyncThunk(
  `${reducerName}/get-subscription`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.subscription,
        urlParams: params,
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const addSubscriptionAction = createAsyncThunk(
  `${reducerName}/create-subscription`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.createSubscription,
        data: body,
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const stopAction = createAsyncThunk(
  `${reducerName}/stop-subscription`,
  async (subscriptionId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.stopSubscription,
        urlParams: { subscriptionId },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const renewAction = createAsyncThunk(
  `${reducerName}/renew-subscription`,
  async (subscriptionId, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.subscription.reactivateSubscription,
        urlParams: { subscriptionId },
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const extendAction = createAsyncThunk(
  `${reducerName}/extend-subscription`,
  async (subscriptionId, { rejectWithValue }) => {
    // TODO lore
  }
);

const subscriptionSlice = createSlice({
  name: reducerName,
  initialState: {
    list: [],
    data: {},
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [addSubscriptionAction.pending]: (state) => {
      state.isLoading = true;
    },
    [addSubscriptionAction.rejected]: (state) => {
      state.isLoading = false;
    },
    [addSubscriptionAction.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getSubscriptionsAction.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSubscriptionsAction.rejected]: (state, { payload }) => {
      state.list = [];
      state.isLoading = false;
      state.error = payload;
    },
    [getSubscriptionsAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.list = payload;
    },
    [getSubscriptionAction.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getSubscriptionAction.rejected]: (state, { payload }) => {
      state.data = [];
      state.isLoading = false;
      state.error = payload;
    },
    [getSubscriptionAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
  },
});

export const actions = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
