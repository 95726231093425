import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useState, useEffect } from "react";
import { myCustomerInvoiceData } from "@store/reducers/profile";
import { unwrapResult } from "@reduxjs/toolkit";

const CATEGORY_CODE_CHARGE = "CHARGE";

const useCredit = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const saleItems = useSelector(
    (state) => state.saleItem.list[CATEGORY_CODE_CHARGE]
  );
  const { account, invoiceData } = useSelector((state) => state.profile);
  const [renderCredit, setRenderCredit] = useState(false);

  useEffect(async () => {
    await dispatch(myCustomerInvoiceData())
      .then(unwrapResult)
      .catch(
        (rejectedValueOrSerializedError) => rejectedValueOrSerializedError
      );

    setRenderCredit(true);
  }, [dispatch]);

  return {
    saleItems: saleItems || [],
    account: account.account,
    invoiceData: invoiceData.invoiceData,
    invoiceDataError: invoiceData.error,
    setRenderCredit,
    intl,
    renderCredit,
  };
};

export default useCredit;
