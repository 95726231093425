import Header from "@components/Header/Header";
import DashboardRoutes from "@components/Router/DashboardRoutes";
import { Sidebar } from "@components/Sidebar";
import { Spinner } from "@components/Spinner";
import useApplicationRoutes from "@hooks/useApplicationRoutes";
import useRenderDashboard from "@hooks/useRenderDashboard";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Dashboard = () => {
  const { render, setToggleSidebar, sidebarHidden } = useRenderDashboard();
  const { applicationRoutes, sidebarRender } = useApplicationRoutes();
  const { account } = useSelector((state) => state.profile.account);
  let { successfulRegistration } = account;
  const { push } = useHistory();

  useEffect(() => {
    if (render && sidebarRender && !successfulRegistration) {
      push("/complete-personal-details");
    }
  }, [render, sidebarRender, sidebarRender]);

  if (!render || !sidebarRender) return <Spinner />;

  return (
    <>
      {successfulRegistration && (
        <div className="page-wrapper">
          <Sidebar
            status={sidebarHidden}
            applicationRoutes={applicationRoutes}
            toggleSidebar={setToggleSidebar}
          />
          <div className="content">
            <Header
              applicationRoutes={applicationRoutes}
              toggleSidebar={setToggleSidebar}
            />
            <main>
              <DashboardRoutes applicationRoutes={applicationRoutes} />
            </main>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
