import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { CardText, CardTitle } from "reactstrap";

const InvoiceData = (props) => {
  const { invoiceData, invoiceDataError } = props;
  return (
    <>
      <CardTitle className="pl-0 pt-3 pb-3 mb-0">
        <FormattedMessage id="main.credit.invoiceData" />
      </CardTitle>
      {invoiceDataError != null ? (
        <>
          <CardText className="no-invoice-data">
            <FormattedMessage id="main.credit.noInvoiceData" />
          </CardText>
          <div className="link-wrapper">
            <Link to="/my-details">
              <FormattedMessage id="main.credit.fillInInvoiceData" />
            </Link>
          </div>
        </>
      ) : (
        <>
          {invoiceData.companyName || invoiceData.vatNumber ? (
            <div className="company-wrapper">
              <CardText className="mb-2">{invoiceData.companyName}</CardText>
              <CardText className="mb-2">{invoiceData.vatNumber}</CardText>
            </div>
          ) : null}
          <div className="name-wrapper">
            <CardText className="mb-2">
              {invoiceData.street} {invoiceData.houseNumber}{" "}
              {invoiceData.busNumber}
            </CardText>
            <CardText className="mb-2">
              {invoiceData.postalCode} {invoiceData.city}
            </CardText>
          </div>
          <div className="link-wrapper">
            <Link to="/my-details">
              <FormattedMessage id="main.credit.adjust" />
            </Link>
          </div>
          <div className="change-vat">
            <i className="fa fa-info" />
            <div className="change-vat-description">
              <CardText className="mb-2">
                <FormattedMessage id="main.credit.adjustVatNumber" />
              </CardText>
              <CardText className="mb-2">
                <FormattedMessage
                  values={{
                    link: (
                      <Link to="/auth/sign-up">
                        <FormattedMessage id="main.credit.here" />
                      </Link>
                    ),
                  }}
                  id="main.credit.vatDescription"
                />
              </CardText>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default InvoiceData;
