import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "discount-vouchers";

export const registerDiscountVouchersAction = createAsyncThunk(
  `${reducerName}/register-discount-voucher`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.discountVouchers.registerDiscountVoucher,
        urlParams: body.urlParams,
        data: body.body,
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

export const getDiscountVoucherAction = createAsyncThunk(
  `${reducerName}/uuid-discount-voucher`,
  async (discountVoucherUuid, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.discountVouchers.getByUuid,
        urlParams: {
          uuid: discountVoucherUuid,
        },
      });
      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const discountVouchersSlice = createSlice({
  name: reducerName,
  initialState: {
    list: {
      data: [],
      isLoading: false,
      error: null,
    },
    discountVoucher: {
      data: null,
      isLoading: false,
      error: null,
    },
  },
  reducers: {},
  extraReducers: {
    [registerDiscountVouchersAction.pending]: (state) => {
      state.list.isLoading = true;
      state.list.error = null;
    },
    [registerDiscountVouchersAction.rejected]: (state, { payload }) => {
      state.list.data = [];
      state.list.isLoading = false;
      state.list.error = payload;
    },
    [registerDiscountVouchersAction.fulfilled]: (state, { payload }) => {
      state.list.isLoading = false;
      state.list.data = payload;
    },
    [getDiscountVoucherAction.pending]: (state) => {
      state.discountVoucher.isLoading = true;
      state.discountVoucher.error = null;
    },
    [getDiscountVoucherAction.rejected]: (state, { payload }) => {
      state.discountVoucher.data = null;
      state.discountVoucher.isLoading = false;
      state.discountVoucher.error = payload;
    },
    [getDiscountVoucherAction.fulfilled]: (state, { payload }) => {
      state.discountVoucher.isLoading = false;
      state.discountVoucher.data = payload;
    },
  },
});

export const actions = discountVouchersSlice.actions;

export default discountVouchersSlice.reducer;
