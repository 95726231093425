import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {apiRoutes} from "@utils";
import {apiAction} from "@utils/http";

const reducerName = "ingenico";

export const createPreSale = createAsyncThunk(
    `${reducerName}/create-presale`,
    async (data, thunkAPI) => {
        try {
            const response = await apiAction({
                ...apiRoutes.salePaymentIngenico.createAPresale,
                data,
            });
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue({error});
        }
    }
);

export const updatePresale = createAsyncThunk(
    `${reducerName}/update-presale`,
    async ({type, ...data}, thunkAPI) => {
        try {
            const response = await apiAction({
                ...apiRoutes.salePaymentIngenico[`updatePresale${type}`],
                data,
            });
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue({error});
        }
    }
);

export const slice = createSlice({
    name: "ingenico",
    initialState: {
        ingenicoUrl: '',
        parameters: {}
    },
    reducers: {},
    extraReducers: {
        [createPreSale.pending]: (state) => {
            state.ingenicoUrl = '';
            state.parameters = {};
        },
        [createPreSale.rejected]: (state) => {
            state.ingenicoUrl = '';
            state.parameters = {};
        },
        [createPreSale.fulfilled]: (state, { payload }) => {
            state.ingenicoUrl = payload.ingenicoUrl;
            state.parameters = payload.parameters;
        },
    }
});

export const actions = slice.actions;

export default slice.reducer;
