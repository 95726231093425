import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "identification-item-type";

export const getIdentificationItemTypes = createAsyncThunk(
  `${reducerName}/identification-item-types`,
  async (_, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.identificationItemType.identificationItemTypes,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);
const identificationItemTypeSlice = createSlice({
  name: reducerName,
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [getIdentificationItemTypes.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getIdentificationItemTypes.rejected]: (state, { payload }) => {
      state.data = [];
      state.isLoading = false;
      state.error = payload;
    },
    [getIdentificationItemTypes.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.data = payload;
    },
  },
});

export const actions = identificationItemTypeSlice.actions;

export default identificationItemTypeSlice.reducer;
