import { styling } from "@assets/defaultStyling";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "theme";

export const getColors = createAsyncThunk(
  `${reducerName}/client-theme`,
  async (_, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.website.colors,
      });
      let colors = [];
      //default colors
      let attributes = styling.css.children[":root"].attributes;
      for (const [key, value] of Object.entries(attributes)) {
        colors[key] = value;
      }
      response.data.map((color) => {
        colors[color.name] = color.value;
      });
      return {
        images: {
          logo: "https://placeimg.com/420/120/tech",
        },
        css: {
          children: {
            ":root": {
              children: {},
              attributes: {
                "--primaryBgColor": colors["--primaryBgColor"],
                "--primaryTextColor": colors["--primaryTextColor"],
                "--activePrimaryBgColor": colors["--activePrimaryBgColor"],
                "--activePrimaryTextColor": colors["--activePrimaryTextColor"],
                "--iconBtnPrimaryBgColor": colors["--iconBtnPrimaryBgColor"],
                "--iconBtnPrimaryBorderColor":
                  colors["--iconBtnPrimaryBorderColor"],
                "--iconBtnPrimaryTextColor":
                  colors["--iconBtnPrimaryTextColor"],
                "--btnPrimaryBgColor": colors["--btnPrimaryBgColor"],
                "--btnPrimaryBorderColor": colors["--btnPrimaryBorderColor"],
                "--btnPrimaryColor": colors["--btnPrimaryColor"],
                "--btnSecondaryBgColor": colors["--btnSecondaryBgColor"],
                "--btnSecondaryBorderColor":
                  colors["--btnSecondaryBorderColor"],
                "--btnSecondaryColor": colors["--btnSecondaryColor"],
                "--linkPrimaryColor": colors["--linkPrimaryColor"],
                "--linkSecondaryColor": colors["--linkSecondaryColor"],
                "--linkActivePrimaryColor": colors["--linkActivePrimaryColor"],
                "--breadCrumbsPrimaryColor":
                  colors["--breadCrumbsPrimaryColor"],
                "--secondaryBgColor": colors["--secondaryBgColor"],
                "--secondaryTextColor": colors["--secondaryTextColor"],
                "--badgeLicensePlateBgColor":
                  colors["--badgeLicensePlateBgColor"],
                "--badgeLicensePlateTextColor":
                  colors["--badgeLicensePlateTextColor"],
                "--badgeLicensePlateBorderColor":
                  colors["--badgeLicensePlateBorderColor"],
                "--promoBgColor": colors["--promoBgColor"],
                "--promoTextColor": colors["--promoTextColor"],
                "--pageBgColor": colors["--pageBgColor"],
              },
            },
          },
          attributes: {},
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const themeSlice = createSlice({
  name: reducerName,
  initialState: {
    isLoading: false,
    error: null,
    images: styling.images, // Should be placed by LocalStorage
    styling: styling.css, // Should be placed by LocalStorage
  },
  reducers: {},
  extraReducers: {
    [getColors.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getColors.rejected]: (state, { payload }) => {
      state.styling = payload.css;
      state.images = payload.images;
      state.isLoading = false;
      state.error = payload;
    },
    [getColors.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.styling = payload.css;
      state.images = payload.images;
    },
  },
});

export const actions = themeSlice.actions;

export default themeSlice.reducer;
