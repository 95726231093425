import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "sale-item-reservations";

export const addSaleItemReservation = createAsyncThunk(
  `${reducerName}`,
  async (data = {}, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.saleItemReservations.addSaleItemReservation,
        data,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const deleteSaleItemReservation = createAsyncThunk(
  `${reducerName}`,
  async (params = {}, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.saleItemReservations.deleteSaleItemReservation,
        urlParams: params,
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const saleItemReservationsSlice = createSlice({
  name: reducerName,
  initialState: {
    isLoading: false,
    error: null,
    reservationId: null,
  },
  reducers: {},
  extraReducers: {
    [addSaleItemReservation.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [addSaleItemReservation.rejected]: (state, { payload }) => {
      state.reservationId = null;
      state.isLoading = false;
      state.error = payload;
    },
    [addSaleItemReservation.fulfilled]: (state, { payload }) => {
      state.isLoading = true;
      state.reservationId = payload.id;
    },
  },
});

export const actions = saleItemReservationsSlice.actions;

export default saleItemReservationsSlice.reducer;
