import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRoutes } from "@utils";
import { apiAction } from "@utils/http";

const reducerName = "multiSafepay";

export const createPreSale = createAsyncThunk(
  `${reducerName}/create-presale`,
  async (data, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentMultiSafepay.createAPresale,
        data,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getPreSale = createAsyncThunk(
  `${reducerName}/get-presale`,
  async (transactionId, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentMultiSafepay.getPresale,
        urlParams: {
          sale_id: transactionId,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

/** THIS WILL ONLY BE USED ON A DEVELOPMENT ENVIRONMENT */
export const processPreSale = createAsyncThunk(
  `${reducerName}/process-presale`,
  async (transactionId, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.salePaymentMultiSafepay.processPresale,
        urlParams: {
          transaction_id: transactionId,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const slice = createSlice({
  name: "multiSafepay",
  initialState: {
    multiSafepayUrl: "",
    presale: null,
    processed: false /* only for dev purposes */,
  },
  reducers: {},
  extraReducers: {
    [createPreSale.pending]: (state) => {
      state.multiSafepayUrl = "";
    },
    [createPreSale.rejected]: (state) => {
      state.multiSafepayUrl = "";
    },
    [createPreSale.fulfilled]: (state, { payload }) => {
      state.multiSafepayUrl = payload.payment_url;
    },
    [getPreSale.pending]: (state) => {
      state.presale = "";
    },
    [getPreSale.rejected]: (state) => {
      state.presale = "";
    },
    [getPreSale.fulfilled]: (state, { payload }) => {
      state.presale = payload;
    },
    [processPreSale.pending]: (state) => {
      state.presale = false;
    },
    [processPreSale.rejected]: (state) => {
      state.presale = false;
    },
    [processPreSale.fulfilled]: (state) => {
      state.presale = true;
    },
  },
});

export const actions = slice.actions;

export default slice.reducer;
