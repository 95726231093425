import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { applicationRoutes } from "@utils";
import { Spinner } from "@components/Spinner";

const AuthRoutes = () => {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {applicationRoutes.auth.map((route) => (
          <Route {...route} key={route.path} />
        ))}
      </Switch>
    </Suspense>
  );
};

export default AuthRoutes;
