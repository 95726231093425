const METHODS = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
};

const apiRoutes = {
  tenant: {
    logo: {
      url: "/tenant/logo.png",
      method: METHODS.GET,
    },
    information: {
      url: "/tenant",
      method: METHODS.GET,
    },
  },
  website: {
    colors: {
      url: "/website-colors",
      method: METHODS.GET,
    },
  },
  authentication: {
    login: {
      url: "/login",
      method: METHODS.POST,
    },
    logout: {
      url: "/logout",
      method: METHODS.DELETE,
    },
    register: {
      url: "/customers",
      method: METHODS.POST,
    },
    forgotPassword: {
      url: "/customers/new-password",
      method: METHODS.POST,
    },
    changePassword: {
      url: "/customers/new-password/:token",
      method: METHODS.PUT,
    },
    validateToken: {
      url: "/customers/validate-token/:token",
      method: METHODS.GET,
    },
    activateToken: {
      url: "/customers/activate/:token",
      method: METHODS.PUT,
    },
    changeEmailAddress: {
      url: "/customers/set-new-username/:token",
      method: METHODS.PUT,
    },
  },
  customer: {
    createCustomer: {
      url: "/customers",
      method: METHODS.POST,
    },
    myAccount: {
      url: "/customers/my-account",
      method: METHODS.GET,
    },
    editMyAccount: {
      url: "/customers/my-account",
      method: METHODS.PUT,
    },
    editSettings: {
      url: "/customers/my-account/settings",
      method: METHODS.PUT,
    },
    myLocation: {
      url: "/customers/my-location",
      method: METHODS.GET,
    },
    editMyLocation: {
      url: "/customers/my-location",
      method: METHODS.PUT,
    },
    newPassword: {
      url: "/customers/new-password",
      method: METHODS.PUT,
    },
  },
  myBalances: {
    url: "/sales/my-balance",
    method: METHODS.GET,
  },
  invoice: {
    invoices: {
      url: "/invoices",
      method: METHODS.GET,
    },
    invoicesPdf: {
      url: "/invoices/:invoiceId/pdf",
      method: METHODS.GET,
    },
    addInvoice: {
      url: "/customers",
      method: METHODS.POST,
    },
  },
  ticket: {
    createInvoiceForTicket: {
      url: "/tickets/invoice",
      method: METHODS.POST,
    },
  },
  saleItemCategory: {
    saleItems: {
      url: "/sale-item-categories/sale-items?includeImage=0",
    },
    saleItemById: {
      url: "/sale-item-categories/sale-items/:saleItemId",
      method: METHODS.GET,
    },
    calculatePrice: {
      url: "/sale-item-categories/sale-items/:saleItemId/calculate-price",
    },
    calculateNextPaymentDate: {
      url: "/sale-item-categories/sale-items/:saleItemId/calculate-next-recurring-payment-date",
    },
  },
  sale: {
    sales: {
      url: "/sales",
      method: METHODS.GET,
    },
  },
  discountVoucherDefinitions: {
    discountVoucherDefinitionByDiscountVoucherCode: {
      url: "/discount-voucher-definitions?discountVoucherUuid=:discountVoucherUuid",
      method: METHODS.GET,
    },
  },
  discountVouchers: {
    registerDiscountVoucher: {
      url: "/discount-vouchers/register/:discountVoucherUuid",
      method: METHODS.PUT,
    },
    getByUuid: {
      url: "/discount-vouchers/uuid/:uuid",
      method: METHODS.GET,
    },
  },
  giftVouchers: {
    giftVouchers: {
      url: "/gift-vouchers",
      method: METHODS.GET,
    },
    giftVouchersPdf: {
      url: "/gift-vouchers/:giftVoucherId/pdf",
      method: METHODS.GET,
    },
    myBalance: {
      url: "/sales/my-balance",
      method: METHODS.GET,
    },
  },
  customerInvoiceData: {
    customerInvoiceData: {
      url: "/my-customer-invoice-data",
      method: METHODS.GET,
    },
    addCustomerInvoiceData: {
      url: "/my-customer-invoice-data",
      method: METHODS.POST,
    },
    editCustomerInvoiceData: {
      url: "/my-customer-invoice-data",
      method: METHODS.PUT,
    },
  },
  subscription: {
    subscriptions: {
      url: "/subscriptions",
      method: METHODS.GET,
    },
    subscription: {
      url: "/subscriptions/:subscriptionId",
      method: METHODS.GET,
    },
    createSubscription: {
      url: "/subscriptions",
      method: METHODS.POST,
    },
    stopSubscription: {
      url: "/subscriptions/:subscriptionId/stop",
      method: METHODS.PUT,
    },
    reactivateSubscription: {
      url: "/subscriptions/:subscriptionId/reactivate",
      method: METHODS.PUT,
    },
  },
  identificationItem: {
    identificationItems: {
      url: "/identification-items/my-identification-items",
      method: METHODS.GET,
    },
    identificationItemCreationRequests: {
      url: "/identification-item-creation-requests",
      method: METHODS.GET,
    },
    createIdentificationItem: {
      url: "/identification-items",
      method: METHODS.POST,
    },
    editIdentificationItem: {
      url: "/identification-items/:identificationItemId",
      method: METHODS.PUT,
    },
    disableIdentificationItem: {
      url: "/identification-items/:identificationItemId/disable",
      method: METHODS.PUT,
    },
    enableIdentificationItem: {
      url: "/identification-items/:identificationItemId/enable",
      method: METHODS.PUT,
    },
    unlinkAllIdentificationItem: {
      url: "/identification-items/:identificationItemId/unlink-all",
      method: METHODS.PUT,
    },
    registerIdentificationItem: {
      url: "/identification-items/register",
      method: METHODS.PUT,
    },
    createIdentificationItemCreationRequest: {
      url: "/identification-item-creation-requests",
      method: METHODS.POST,
    },
    cancelIdentificationItemCreationRequest: {
      url: "/identification-item-creation-requests/:identificationItemCreationRequestId/cancel",
      method: METHODS.PUT,
    },
  },
  salePaymentProvider: {
    info: {
      url: "/sales/payment-provider/info",
    },
  },
  salePaymentAdyen: {
    getPaymentMethods: {
      url: "/sales/adyen/payment-methods",
    },
    makePayment: {
      url: "/sales/adyen",
      method: METHODS.POST,
    },
    disableStoredPaymentDetails: {
      url: "/sales/adyen/disable-stored-payment-details",
      method: METHODS.POST,
    },
    submitAdditionalPaymentDetails: {
      url: "/sales/adyen",
      method: METHODS.PUT,
    },
  },
  salePaymentIngenico: {
    createAPresale: {
      url: "/sales/ingenico",
      method: METHODS.POST,
    },
    updatePresaleSuccess: {
      url: "/sales/ingenico/success",
    },
    updatePresaleFailed: {
      url: "/sales/ingenico/failed",
    },
  },
  salePaymentMultiSafepay: {
    createAPresale: {
      url: "/sales/multisafepay",
      method: METHODS.POST,
    },
    getPresale: {
      url: "/sales/multisafepay/:sale_id",
      method: METHODS.GET,
    },
    processPresale: {
      url: "/sales/multisafepay/process?transaction_id=:transaction_id",
      method: METHODS.POST,
    },
    updatePresaleRedirect: {
      url: "/sales/multisafepay/redirect?transaction_id=:transaction_id",
    },
  },
  identificationItemType: {
    identificationItemTypes: {
      url: "/identification-item-types",
      method: METHODS.GET,
    },
  },
  locations: {
    locations: {
      url: "/locations",
      method: METHODS.GET,
    },
  },
  prepaidSaleItems: {
    myPrepaidSaleItems: {
      url: "/prepaid-sale-items",
      method: METHODS.GET,
    },
    getMyPrepaidSaleItemById: {
      url: "/prepaid-sale-items/:myPrepaidSaleItemId/pdf",
      method: METHODS.GET,
    },
  },
  saleItemReservations: {
    addSaleItemReservation: {
      url: "/sale-item-reservations",
      method: METHODS.POST,
    },
    deleteSaleItemReservation: {
      url: "/sale-item-reservations/:saleItemReservationId/close",
      method: METHODS.PUT,
    },
  },
};

export default apiRoutes;
