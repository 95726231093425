import AuthRoutes from "@components/Router/AuthRoutes";

const AuthLayout = () => {
  return (
    <div className="Auth">
      <AuthRoutes />
    </div>
  );
};

export default AuthLayout;
