import { FormattedMessage } from "react-intl";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const Breadcrumbs = ({ location, applicationRoutes }) => {
  const routes = applicationRoutes.dashboard;
  const crumbsArr = location.pathname.split("/").filter(Boolean);
  const currentCrumb = routes.find((r) => r.path === location.pathname);

  let route = "/";
  const buildCrumbs = crumbsArr.map((crumb, index) => {
    route += crumb;
    const foundedCrumb = routes.find((r) => r.path === route);

    if (foundedCrumb) {
      route += "/";
    } else {
      return;
    }

    if (foundedCrumb?.path === currentCrumb?.path) {
      return null;
    }

    return (
      <BreadcrumbItem key={index}>
        <Link to={foundedCrumb.path} className={"capitalize"}>
          <FormattedMessage id={foundedCrumb.sidebarName} />
        </Link>
      </BreadcrumbItem>
    );
  });

  if (!currentCrumb || !currentCrumb.sidebarName) return null;

  return (
    <div className="Breadcrumbs">
      <h3 className="breadcrumbs__title">
        <FormattedMessage id={currentCrumb.sidebarName} />
      </h3>
      <Breadcrumb>
        {buildCrumbs}
        <BreadcrumbItem active className="capitalize">
          <FormattedMessage id={currentCrumb.sidebarName} />
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};

export default withRouter(Breadcrumbs);
