import { Spinner } from "@components/Spinner";
import { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import NotFound from "./NotFound";

const CompleteAccountRoutes = ({ applicationRoutes }) => {
  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        {applicationRoutes.completeAccount.map((route) => (
          <Route {...route} key={route.path} />
        ))}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default CompleteAccountRoutes;
