import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "tenant";

export const getTenantInformationAction = createAsyncThunk(
  `${reducerName}/information`,
  async (_, thunkAPI) => {
    try {
      const response = await apiAction({
        ...apiRoutes.tenant.information,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

const tenantSlice = createSlice({
  name: reducerName,
  initialState: {
    logo: "",
    companyName: "",
    userAgreementUrl: "",
    error: null,
    isLoading: false,
  },
  reducers: {},
  extraReducers: {
    [getTenantInformationAction.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getTenantInformationAction.rejected]: (state, { payload }) => {
      state.logo = null;
      state.isLoading = false;
      state.error = payload;
    },
    [getTenantInformationAction.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.logo = "data:image/jpeg;base64," + payload.logo;
      state.companyName = payload.companyName;
      state.userAgreementUrl = payload.userAgreementUrl;
    },
  },
});

export const actions = tenantSlice.actions;

export default tenantSlice.reducer;
