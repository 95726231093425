import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes, http as apiAction } from "@utils";

const reducerName = "invoice";

export const getInvoicesAction = createAsyncThunk(
  `${reducerName}`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.invoice.invoices,
        data: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const getInvoiceById = createAsyncThunk(
  `${reducerName}-by-id`,
  async (params, thunkAPI) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.invoice.invoicesPdf,
        urlParams: params,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error });
    }
  }
);

export const createInvoiceForTicket = createAsyncThunk(
  `${reducerName}/create-invoice-for-ticket`,
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await apiAction({
        ...apiRoutes.ticket.createInvoiceForTicket,
        data: body,
      });

      return data;
    } catch (error) {
      return rejectWithValue({ error });
    }
  }
);

const invoiceSlice = createSlice({
  name: reducerName,
  initialState: {
    pagedInvoices: {
      invoices: [],
      totalElements: 0,
      totalPages: 0,
      size: 10,
      page: 0,
    },
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: {
    [createInvoiceForTicket.pending]: (state) => {
      state.isLoading = true;
    },
    [createInvoiceForTicket.rejected]: (state) => {
      state.isLoading = false;
    },
    [createInvoiceForTicket.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [getInvoicesAction.pending]: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    [getInvoicesAction.rejected]: (state, { payload }) => {
      state.pagedInvoices = {
        invoices: [],
      };
      state.isLoading = false;
      state.error = payload;
    },
    [getInvoicesAction.fulfilled]: (state, { payload, meta: { arg } }) => {
      state.pagedInvoices = {
        ...state.pagedInvoices,
        ...payload,
        size: arg.size,
        page: arg.page,
      };
      state.isLoading = false;
    },
  },
});

export const actions = invoiceSlice.actions;

export default invoiceSlice.reducer;
